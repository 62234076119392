import { PartnerDto } from './partner.dto';

export class PartnerModel {
    id: string;
    name: string;
    fullName: string;
    nip: string;
    phone: string;
    email: string;
    address: string | null;
    notes: string;

    constructor(data: PartnerDto) {
        this.id = data.id;
        this.name = data.name;
        this.fullName = data.fullName;
        this.nip = data.nip;
        this.phone = data.phone;
        this.email = data.email;
        this.address = data.address ?? null;
        this.notes = data.notes;
    }
}
