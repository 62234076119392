import { PersonalDetailsDto } from './personal-details.dto';

export class PersonalDetailsModel {
    firstname: string;
    surname: string;
    email: string;
    phone: string;

    constructor(data: PersonalDetailsDto) {
        this.firstname = data.firstname;
        this.surname = data.surname;
        this.email = data.email;
        this.phone = data.phone;
    }
}
