import { ItineraryAddressDto, ItineraryDto } from './itinerary.dto';

export class Itineraries {
    items: Itinerary[];
    startTime: string | null = null;
    constructor(data: ItineraryDto[]) {
        this.items = data.map((item) => new Itinerary(item));
        if (data[0]?.startTime) {
            const startDate = new Date(data[0].startTime);
            this.startTime = `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
        }
    }
}

export class Itinerary {
    start: ItineraryPoint;
    stop: ItineraryPoint;
    tour: ItineraryTour;
    constructor(data?: ItineraryDto) {
        this.start = new ItineraryPoint(data?.startAddress, data?.startItineraryPointType);
        this.stop = new ItineraryPoint(data?.stopAddress, data?.stopItineraryPointType);

        this.tour = new ItineraryTour(data);
    }
}

export class ItineraryPoint {
    address: ItineraryAddress | null = null;
    type: 'start' | 'stop' | 'transfer' | null = null;
    constructor(data?: ItineraryAddressDto, type?: 'start' | 'stop' | 'transfer') {
        if (data) {
            this.address = new ItineraryAddress(data);
            this.type = type ?? null;
        }
    }
}

export class ItineraryTour {
    startTime: string | null = null;
    stopTime: string | null = null;
    vehicle: string | null = null;
    driver: string | null = null;
    phones: string[] = [];
    constructor(data?: ItineraryDto) {
        if (data) {
            this.startTime = time(data.startTime);
            this.stopTime = time(data.stopTime);
            this.vehicle = data.vehicleSideNumber ?? null;
            this.driver = `${data.mainDriver?.firstname ?? ''} ${data.mainDriver?.surname ?? ''}`.trim() || null;
            this.phones = data.mainDriver?.phoneNumbers.map((phoneNumber) => phoneNumber.number) ?? [];
        }
    }
}

export class ItineraryAddress {
    full: string | null;
    city: string | null;
    postalCode: string | null;
    constructor(data: ItineraryAddressDto) {
        this.full = [data?.city ?? null, data?.postalCode?.replace('-', '‑') ?? null].filter((value) => value !== null).join(', ') ?? null;
        this.city = data?.city ?? null;
        this.postalCode = data?.postalCode?.replace('-', '‑') ?? null;
    }
}

const time = (time: string | null) => {
    if (time) {
        const startDate = new Date(time);
        return `${startDate.getHours().toString().padStart(2, '0')}:${startDate.getMinutes().toString().padStart(2, '0')}`;
    }
    return null;
};
