import { AddressDto, GeoAddressDto } from './address.dto';
import { GeoPositionModel } from './geoposition.model';

export class AddressModel {
    id: string;
    city: string;
    street: string;
    houseNumber: string;
    flatNumber: string;
    postalCode: string;
    voivodeship: string;
    country: string;

    constructor(event: AddressDto) {
        this.id = event.id || '';
        this.city = event.city || '';
        this.street = event.street || '';
        this.houseNumber = event.houseNumber || '';
        this.flatNumber = event.flatNumber || '';
        this.postalCode = event.postalCode || '';
        this.voivodeship = event.voivodeship || '';
        this.country = event.country || '';
    }
}

export class GeoAddressModel extends AddressModel {
    geoPosition: GeoPositionModel;

    constructor(event: GeoAddressDto) {
        super(event);
        this.geoPosition = {
            latitude: event.geoPosition?.latitude || null,
            longitude: event.geoPosition?.longitude || null,
            isPoint: event.geoPosition?.isPoint || null,
        };
    }
}
