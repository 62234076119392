import { BOOKING_TYPES } from '../enums';
import { AddressModel } from './address.model';
import { GeoPositionModel } from './geoposition.model';

export enum WAYPOINT_TYPES_DTO {
    BOOKING_START = 1,
    BOOKING_STOP = 2,
    TRANSFER = 3,
    TASK = 4,
    START = 5,
    DRAFT = 7,
}

export interface WaypointDto {
    id: string;
    type: WAYPOINT_TYPES_DTO;
    order: number;
    bookingType?: BOOKING_TYPES;
    taskId?: string;
    bookingId: string;
    transferPointId?: string;
    draftPointId?: string;
    time?: string;
    length?: number;
    geoPosition?: GeoPositionModel;
    address?: AddressModel;
    transfersIn?: WaypointTransferDto[];
    transfersOut?: WaypointTransferDto[];
}
export interface WaypointTransferDto {
    bookingType: BOOKING_TYPES;
    bookingId: string;
    order: number;
}
