import { BOOKING_TYPES } from '../enums';
import { WAYPOINT_TYPES } from '../enums/waypoint-types.enum';
import { WAYPOINT_TYPES_DTO, WaypointDto, WaypointTransferDto } from './waypoint.dto';

export class WaypointModel {
    id: string | null;
    type: WAYPOINT_TYPES;
    pointId: string;
    time: Date | null;
    length: number | null;
    transfersIn?: WaypointTransferModel[];
    transfersOut?: WaypointTransferModel[];
    sequence: number | null;

    constructor(event: WaypointDto) {
        this.id = event.id;
        this.sequence = null;
        switch (event.type) {
            case WAYPOINT_TYPES_DTO.BOOKING_START:
                this.pointId = event.bookingId;
                if (event.bookingType === BOOKING_TYPES.PASSENGER) {
                    this.type = WAYPOINT_TYPES.PASSENGER_START;
                } else {
                    this.type = WAYPOINT_TYPES.PARCEL_START;
                }
                break;
            case WAYPOINT_TYPES_DTO.BOOKING_STOP:
                this.pointId = event.bookingId;
                if (event.bookingType === BOOKING_TYPES.PASSENGER) {
                    this.type = WAYPOINT_TYPES.PASSENGER_STOP;
                } else {
                    this.type = WAYPOINT_TYPES.PARCEL_STOP;
                }
                break;
            case WAYPOINT_TYPES_DTO.TASK:
                this.pointId = <string>event.taskId;
                this.type = WAYPOINT_TYPES.TASK;
                break;
            case WAYPOINT_TYPES_DTO.START:
                this.pointId = <string>event.taskId;
                this.type = WAYPOINT_TYPES.START;
                break;
            case WAYPOINT_TYPES_DTO.TRANSFER:
                this.pointId = <string>event.transferPointId;
                this.type = WAYPOINT_TYPES.TRANSFER;
                break;
            case WAYPOINT_TYPES_DTO.DRAFT:
                this.pointId = <string>event.draftPointId;
                this.type = WAYPOINT_TYPES.DRAFT;
                break;
        }

        this.time = null;
        if (event.time) {
            this.time = new Date(new Date(event.time).toLocaleString('en-US', { timeZone: 'Europe/Warsaw' }));
        }

        this.length = event.length ?? null;

        if (this.type === WAYPOINT_TYPES.TRANSFER) {
            this.transfersIn = event.transfersIn?.map((transfer) => new WaypointTransferModel(transfer)) || [];
            this.transfersOut = event.transfersOut?.map((transfer) => new WaypointTransferModel(transfer)) || [];
        }
    }
}

export class WaypointTransferModel {
    bookingId: string;
    bookingType: BOOKING_TYPES;
    constructor(data: WaypointTransferDto) {
        this.bookingId = data.bookingId;
        this.bookingType = data.bookingType;
    }
}

export class WaypointNewModel {
    id: string | null;
    type: WAYPOINT_TYPES;
    pointId: string;
    time: Date | null;
    length: number | null;
    transfersIn?: WaypointTransferModel[];
    transfersOut?: WaypointTransferModel[];
    sequence: number | null;

    constructor(pointId: string, type: WAYPOINT_TYPES) {
        this.id = null;
        this.type = type;
        this.pointId = pointId;
        this.time = null;
        this.length = null;
        this.sequence = null;
        if (type === WAYPOINT_TYPES.TRANSFER) {
            this.transfersIn = [];
            this.transfersOut = [];
        }
    }
}
